import { css } from "@emotion/react"
import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Block from "../../components/common/Block/Block"
import CTA from "../../components/common/CTA"
import FeatureList from "../../components/common/Feature-list/FeatureList"
import FooterLP from "../../components/common/footer-lp"
import Frame from "../../components/common/frame"
import Hero from "../../components/common/Hero/Hero"
import PopupSignup from "../../components/common/popup-sign-up"
import Testimonial from "../../components/common/testimonial-component/testimonial"
import VerticalTabs from "../../components/common/VerticalTabComponent/VerticalTabs"
import Navigation from "../../components/navigation"
import Slide from "../../components/utility/slide_content"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/ads-page.scss"

const AdsPage = ({ pageContext }) => {
  const { template } = pageContext
  const [faqIndex, setFaqIndex] = useState(0)
  const [popupVisibility, setPopupVisibility] = useState(false)

  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }

  return (
    <StaticQuery
      query={graphql`
        query adsPage {
          SuperOps {
            links(where: { section: "Footer Security" }) {
              linkName
              slug
            }
          }
        }
      `}
      render={data => (
        <div className="ads-page">
          <>
            <Frame
              seo={template.seo}
              ogType="website"
              IsFooterVisble="No"
              newButton
            >
              <div>
                <PopupSignup
                  visibility={popupVisibility}
                  togglePopup={togglePopup}
                  ctaText={template.signupFormCtaText}
                  title={template.signupFormTitle}
                  disallowCommonDomains
                />
              </div>
              <header>
                <Navigation
                  color={template.navigationBackgroundColor}
                  page="Home"
                  primaryButtonText="GET STARTED FOR FREE"
                  // formOneAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
                  // formTwoAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
                  newButton
                  signupPopup
                  disallowCommonDomains
                />
              </header>
              <main>
                {template.components.map(field => {
                  switch (field.__typename) {
                    case "SuperOps_Hero": {
                      return (
                        <section className="hero">
                          <div
                            css={css`
                              .bg-lines {
                                background-image: url(${field.backgroundImage
                                  .url && field.backgroundImage.url});
                              }
                              .tick-logo path {
                                fill: ${field.heroExcerpt};
                              }
                            `}
                          >
                            <Hero
                              background={field.heroBgColor}
                              tagName={field.tag}
                              heading={field.heroheading.text}
                              backgroundImage={field.backgroundColor}
                              maxDescriptionWidth="500px"
                              benefits={field.heroExcerptList}
                              // formOneAPI={
                              //   process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                              // }
                              // formTwoAPI={
                              //   process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                              // }
                              primaryButtonText={field.primaryButtonText}
                              primaryButtonLink={field.primaryButtonLink}
                              image={field.heroImage.url}
                              newButton
                              inputEmail={
                                template.signupFormTitle ? false : true
                              }
                              openPopUp={() => togglePopup()}
                              referBox={template.signupFormTitle ? true : false}
                            />
                          </div>
                        </section>
                      )
                    }
                    case "SuperOps_Testimonial": {
                      return (
                        <section className="single-testimonial">
                          <Slide delay="400">
                            <Testimonial type="single_3" data={field} />
                          </Slide>
                        </section>
                      )
                    }
                    case "SuperOps_FaqComponent": {
                      return <VerticalTabs data={field} />
                    }
                    case "SuperOps_Block": {
                      return (
                        <Block
                          html={field.blockContent?.html}
                          heading={field.blockHeading}
                          description={field.blockDescription}
                          imageTitle={field.blockImageTitle}
                          image={field.blockImage}
                          isImage={field.blockIsIllustration}
                          isVideo={field.blockIsVideo}
                          lgLeft={6}
                          lgRight={6}
                        />
                      )
                    }
                    case "SuperOps_Card": {
                      return (
                        <section className="mt60">
                          <Container className="Layout-container">
                            <FeatureList
                              title={field.title[0]}
                              heading={field.text}
                              description={field.subtext}
                              image={field.image}
                            />
                          </Container>
                        </section>
                      )
                    }
                    case "SuperOps_CtaFeature": {
                      return (
                        <section className="mt100">
                          <CTA
                            data={[field.ctaBox]}
                            className="Layout-container"
                            maxHeadingWidth="575px"
                            maxDescriptionWidth="500px"
                            lgLeft={7}
                            lgRight={4}
                            rightStyles={{ alignItems: "center" }}
                            formOneAPI={
                              process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                            }
                            formTwoAPI={
                              process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                            }
                            newButton
                            newDesign
                            disallowCommonDomains
                          />
                        </section>
                      )
                    }
                    default:
                      return <></>
                  }
                })}

                <FooterLP items={data.SuperOps.links[0]} />
              </main>
            </Frame>
          </>
        </div>
      )}
    />
  )
}

export default AdsPage
